let connectSlide = 4
let lastSlide = 9
let lastTime = Date.now()
let slideTime = () => {
  let nowTime = Date.now()
  if (nowTime - lastTime < 350) return false
  lastTime = nowTime
  return true
}

export const ws = (u, connected) => {
  if (!connected) {
    u('slide', slide => {
      if (slide.mode === 'intro') slide.direction = '>'
      if (slide.mode === 'test') slide.direction = '<'
      slide.mode = 'connect'
      return slide
    })
  }
  u('ws', ws => connected)
}

export const setPending = u => u('notice', _ => 'pending')

export const insertTransaction = (u, tx) => u('txs', txs => {
  txs[tx.hash] = {status: 'pending', hash: tx.hash, confirmations: 0}
  return txs
})

export const updateTransaction = (u, tHash, update) => u('txs', tHash, tx => Object.assign({}, tx, update))

export const updateConfirmations = (u, txHash, confirmations) => u('txs', txHash, 'confirmations', _ => confirmations)

export const nextSlide = (u, connected) => {
  if (!slideTime()) return
  u('slide.direction', _ => '>')
  u('slide.index', i => {
    if (!connected && i >= connectSlide) return connectSlide
    if (i === lastSlide) return lastSlide
    return ++i
  })
}

export const accounts = (u, accounts) => u('accounts', _ => accounts)

export const netId = (u, netId) => u('netId', _ => netId)

export const notice = (u, notice) => {
  u('notice', _ => notice)
  setTimeout(_ => u('notice', _ => ''), 2000)
}

export const setBalance = (u, balance) => {
  u('balance', _ => balance)
  u('recentBalance', _ => true)
  setTimeout(_ => u('recentBalance', _ => false), 2000)
}

export const setMode = (u, mode) => u('slide.mode', _ => mode)

export const browserSupported = (u, support) => u('browserSupported', _ => support)

export const os = (u, os) => {
  u('osChecked', _ => true)
  u('os', _ => os)
}
export const releases = (u, releases) => u('releases', _ => releases)
export const toggleHeader = (u, open) => {
  u('headerExapnd', headerExapnd => open !== undefined ? open : !headerExapnd)
}
