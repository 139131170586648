const defaultState = () => ({
  releases: {
    mac: {
      version: 'latest',
      ext: 'dmg',
      name: '',
      link: 'https://github.com/floating/frame/releases/download/v0.0.5-alpha.6/Frame-mac-0.0.5-alpha.6.dmg'
    },
    linux: {
      version: 'latest',
      ext: 'AppImage',
      name: '',
      link: 'https://github.com/floating/frame/releases'
    },
    windows: {
      version: 'latest',
      ext: 'exe',
      name: '',
      link: 'https://github.com/floating/frame/releases'
    },
    unknown: {
      version: 'latest',
      name: '',
      link: 'https://github.com/floating/frame/releases'
    }
  },
  ws: false,
  slide: {
    mode: 'connect',
    direction: '>'
  },
  accounts: [],
  netId: 4,
  notice: '',
  txs: {},
  balance: 0,
  recentBalance: false,
  headerExapnd: false,
  os: '',
  osChecked: false,
  browserSupported: true
})

export default typeof window !== 'undefined' ? window.__initialState || defaultState() : defaultState()
