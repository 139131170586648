import React from 'react'
import ReactDOM from 'react-dom'
import Restore from 'react-restore'

import App from '../App'
import store from '../store'

import io from 'socket.io-client'
import ethProvider from 'eth-provider'
import Web3 from 'web3'

let dev = process.env.NODE_ENV !== 'production'

// let provider = ethProvider('frame')
// console.log(window.ethereum)

window.socket = io(dev ? 'localhost:8080' : '')
// console.log('new')
// let providers = {
//   injected: () => window.ethereum,
//   ws: () => ethProvider(['ws://127.0.0.1:1248']),
//   http: () => ethProvider(['http://127.0.0.1:1248']),
//   default: () => ethProvider()
// }
// // let provider = window.ethereum
// let provider = providers['injected']()

let provider = ethProvider('frame')

//
//
// // send (method, params = []) {
// //   if (!method || typeof method !== 'string') return new Error('Method is not a valid string.')
// //   if (!(params instanceof Array)) return new Error('Params is not a valid array.')
// //   const payload = { jsonrpc: '2.0', id: this.nextId++, method, params }
// //   const promise = new Promise((resolve, reject) => { this.promises[payload.id] = { resolve, reject } })
// //   this.connection.send(payload)
// //   return promise
// // }
//
// console.log('   ')
// console.log('   ')
// console.log('   ')
// console.log('   ')
// console.log('________')
// console.log('   ')
// console.log('   ')
// console.log('   ')

provider.on('connect', () => setup())
provider.on('close', () => { if (store('ws')) store.ws(false) })
provider.on('networkChanged', netId => {
  // console.log('NETWORK CHANGE', netId)
  store.netId(netId)
})
provider.on('accountsChanged', accounts => {
  store.accounts(accounts)
  getBalance()
})
// console.log('before')
// console.log(provider)
// provider.on('accountsChanged', store.accounts)
window.web3 = new Web3(provider) // window.web3 ? window.web3.currentProvider : null
// console.log('after')
// console.log(provider)

let getBalance = () => {
  let account = store('accounts', 0)
  if (account) {
    window.web3.eth.getBalance(account).then(res => {
      let balance = Web3.utils.fromWei(res)
      if (store('balance') !== balance) store.setBalance(balance)
    }).catch(err => {
      if (err) return console.log('getBalance Error ', err)
    })
  } else {
    store.setBalance(0)
  }
}

let setupTimer

const setup = () => {
  clearTimeout(setupTimer)
  // console.log('in setup 12000')
  // console.log(provider)
  provider.send('net_version').then(netId => { // web3 clobbers send...
    // console.log('nettsdlkmflksdmflkdsmtID', netId)
    if (netId) {
      store.netId(netId)
      if (!store('ws')) store.ws(true)
    }
    provider.enable().then(accounts => {
      // console.log('got accounts from enable', accounts)
      // if (accounts.length === 0) setupTimer = setTimeout(() => setup(), 1000)
      store.accounts(accounts)
      getBalance()
    }).catch(e => {
      console.log('Setup Error: ', e)
      store.accounts([])
      getBalance()
      // setupTimer = setTimeout(() => setup(), 1000)
    })
  }).catch(e => {
    console.log('Setup Error: ', e)
    store.accounts([])
    getBalance()
    // setupTimer = setTimeout(() => setup(), 1000)
  })
  setupTimer = setTimeout(() => setup(), 1000)
}

setup()

// let getStatus = () => {
//   window.web3.eth.getAccounts((err, accounts) => {
//     if (err) {
//       console.log(' ')
//       console.log(' ')
//       console.log('getAccounts Error ', err)
//       console.log(' ')
//       console.log(' ')
//       store.accounts([])
//       store.setMode('connect')
//     } else {
//       console.log('accounts', accounts)
//       store.accounts(accounts)
//       getBalance()
//     }
//   })
//   window.web3.eth.net.getId((err, netId) => {
//     if (err) return console.log('getNetwork Error ', err)
//     store.netId(netId)
//   })
// }

//
// provider.on('connect', () => {
//   if (!store('ws')) store.ws(true)
//   console.log('Connect in Dapp')
//   window.web3.eth.getAccounts((err, accounts) => {
//     if (err) return console.log('getAccounts Error ', err)
//     store.accounts(accounts)
//     getBalance()
//   })
//   // window.web3.eth.net.getId((err, netId) => {
//   //   if (err) return console.log('getNetwork Error ', err)
//   //   store.netId(netId)
//   // })
//   // provider.on('close', () => {
//   //   if (store('ws')) store.ws(false)
//   // })
// })
// }

let accountTimer

let getAccounts = () => {
  clearTimeout(accountTimer)
  provider.send('eth_accounts').then(accounts => { // web3 clobbers send...
    // console.log('nettsdlkmflksdmflkdsmtID', netId)
    if (accounts.length) {
      store.accounts(accounts)
      getBalance()
    } else {
      accountTimer = setTimeout(() => getAccounts(), 1000)
    }
  }).catch(e => {
    console.log('getAccounts error:', e)
    accountTimer = setTimeout(() => getAccounts(), 1000)
  })
}

store.observer(() => {
  let mode = store('slide.mode')
  let ws = store('ws')
  let accounts = store('accounts')
  if (mode === 'connect' && ws && accounts.length) {
    if (accounts.length) {
      setTimeout(() => store.setMode('test'), 900)
    } else {
      accountTimer = setTimeout(() => getAccounts(), 1000)
    }
  }
})
// setTimeout(() => store.setMode('connect'), 1700)

let os = () => {
  let userAgent = window.navigator.userAgent
  let platform = window.navigator.platform
  if (['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].indexOf(platform) !== -1) return 'mac'
  if (['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(platform) !== -1) return 'windows'
  if (['iPhone', 'iPad', 'iPod'].indexOf(platform) !== -1) return 'ios'
  if (/Android/.test(userAgent)) return 'android'
  if (/Linux/.test(platform)) return 'linux'
  return ''
}

store.os(os())
store.browserSupported(navigator.userAgent.indexOf('Chrome') !== -1 || navigator.userAgent.indexOf('Firefox') !== -1)
// store.toggleHeader(true)

let Frame = Restore.connect(App, store)
ReactDOM.render(<Frame />, document.getElementById('root'))

window.onload = () => { document.body.className += ' loaded' }
