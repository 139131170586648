/* globals socket */

import React from 'react'
// import DevTools from 'restore-devtools'
import svg from '../../../resource/svg'

// const React = require('react')
// const ReactDOM = require('react-dom')
// const Restore = require('react-restore')

import Drop from './Drop'

class App extends React.Component {
  constructor (...args) {
    super(...args)
    this.state = {
      email: '',
      emailSuccess: false,
      emailError: false
    }
  }
  frameStatus () {
    return (
      <div className={this.store('headerExapnd') ? 'header headerExapnd' : 'header'}>
        <div className='dropTop'>
          <div>
            {'Need help?'}
            <a target='_blank' href='https://github.com/floating/frame/issues/new'> {'Open an issue'} </a>
            {'or'}
            <a target='_blank' href='https://gitter.im/framehq/general'> {'come chat'}</a>
          </div>
          <div className='extLink' style={this.store('ws') ? { opacity: 1, pointerEvents: 'auto' } : { opacity: 0, pointerEvents: 'none' }}>
            {'Companion extensions'}
            <a className='extIcon' target='_blank' href='https://addons.mozilla.org/en-US/firefox/addon/frame-extension'>{svg.firefox(25)}</a>
            <a className='extIcon' target='_blank' href='https://chrome.google.com/webstore/detail/frame/ldcoohedfbjoobcadoglnnmmfbdlmmhf'>{svg.chrome(25)}</a>
          </div>
        </div>
        <div className='dropMain'><Drop /></div>
        <div className='statusBar' onClick={() => this.store.toggleHeader()}>
          <div className='statusIndicator'>
            <div className={this.store('ws') ? 'statusIndicatorBadge statusIndicatorBadgeConnected' : 'statusIndicatorBadge'} />
            <div className='statusIndicatorText'>{this.store('ws') ? 'connected to frame' : 'not connected to frame'}</div>
          </div>
          <div className={this.store('headerExapnd') ? 'toggleHeader toggleHeaderOpened' : 'toggleHeader'}>{svg.octicon('chevron-down', { height: '28px' })}</div>
        </div>
      </div>
    )
  }
  emailSubmit () {
    if (window.socket) {
      socket.emit('emailSignup', this.state.email, (err) => {
        if (err) {
          this.setState({ emailError: true })
        } else {
          this.setState({ emailSuccess: true, email: '' })
        }
        setTimeout(() => {
          this.setState({ emailSuccess: false, emailError: false })
        }, 1500)
      })
    }
  }
  renderEmailInternal () {
    if (this.state.emailSuccess) {
      return (
        <div className='emailWrap'>
          <div className='emailMessage'> {'Success!'} </div>
        </div>
      )
    } else if (this.state.emailError) {
      return (
        <div className='emailWrap'>
          <div className='emailMessage invalid'> {'Invalid'} </div>
        </div>
      )
    } else {
      return (
        <div className='emailWrap'>
          <input value={this.state.email} className='emailInput' onChange={e => this.setState({ email: e.target.value })} onKeyPress={e => { if (e.key === 'Enter') this.emailSubmit() }} />
          <div className='emailSubmit' onClick={() => this.emailSubmit()}>{'Sign Up'}</div>
        </div>
      )
    }
  }
  downloads () {
    let os = this.store('os')
    let releases = this.store('releases')
    let dl = (link, unknown) => {
      this.store.toggleHeader(true)
      if (unknown) {
        window.open(link)
      } else { // Trigger download
        window.location = link
      }
    }
    if (os === 'mac') {
      return (
        <div key={os} className='downloads' onClick={() => dl(releases.mac.link)}>
          {svg.octicon('cloud-download', { height: '44px' })}
          <div className='releaseText'>
            <div>{'Download for macOS'}</div>
            <div className='releaseVersion'>{releases.mac.version + '.' + releases.mac.ext}</div>
          </div>
        </div>
      )
    } else if (os === 'linux') {
      return (
        <div key={os} className='downloads' onClick={() => dl(releases.linux.link)}>
          {svg.octicon('cloud-download', { height: '44px' })}
          <div className='releaseText'>
            <div>{'Download for Linux'}</div>
            <div className='releaseVersion'>{releases.linux.version + '.' + releases.linux.ext}</div>
          </div>
        </div>
      )
    } else if (os === 'windows') {
      return (
        <div key={os} className='downloads' onClick={() => dl(releases.windows.link)}>
          {svg.octicon('cloud-download', { height: '44px' })}
          <div className='releaseText'>
            <div>{'Download for Windows'}</div>
            <div className='releaseVersion'>{releases.windows.version + '.' + releases.windows.ext}</div>
          </div>
        </div>
      )
    } else {
      return (
        <div key={os} className='downloads' onClick={() => dl(releases.unknown.link, true)}>
          {svg.octicon('cloud-download', { height: '44px' })}
          <div className='releaseText'>
            <div>{this.store('osChecked') ? 'Download Latest' : ''}</div>
          </div>
        </div>
      )
    }
  }
  render () {
    return (
      <div id='home'>
        <div className='bg' />
        {this.frameStatus()}
        <div className='section'>
          <div className='sectionInner'>
            <div className='logo'>
              <div className='logoTop'>{svg.logo(120)}</div>
              <div className='logoBot'>{svg.logo(120)}</div>
            </div>
            <div className='titleWrap'>
              <div className='title'>{'Frame'}</div>
              <div className='subtitle'>{'An OS-level Ethereum Interface'}</div>
            </div>
            {this.downloads()}
            <div className='devNotice'>
              <a className='allDownloadsLink' href='https://github.com/floating/frame/releases'>{'View all downloads'}{svg.octicon('link-external', { height: '18px' })}</a>
            </div>
            <div className='extensions'>
              <div className='extIcons'>
                <a className='extIcon' target='_blank' href='https://addons.mozilla.org/en-US/firefox/addon/frame-extension'>{svg.firefox(30)}</a>
                <a className='extIcon' target='_blank' href='https://chrome.google.com/webstore/detail/frame/ldcoohedfbjoobcadoglnnmmfbdlmmhf'>{svg.chrome(30)}</a>
              </div>
              <div className='extTitle' style={{ fontSize: '15px' }}>{'companion extensions'}</div>
              <div className='extTitle' style={{ fontSize: '12px' }}>{'inject frame into any browser dapp'}</div>
            </div>
            <div className='description'>{'Frame is an OS-level Ethereum interface that lets you use standalone signers, such as a Ledger or Trezor, to interact with dapps and the Ethereum network'}</div>
            <div className='features'>
              <div className='feature'>
                {svg.octicon('checklist', { height: '35px' })}
                {'Hardware Wallets'}
              </div>
              <div className='feature'>
                {svg.octicon('shield', { height: '35px' })}
                {'Dapp Permissions'}
              </div>
              <div className='feature'>
                {svg.octicon('desktop-download', { height: '35px' })}
                {'Cross Platform'}
              </div>
            </div>
            <div className='demo'>
              <div className='demoSliceWrap'>
                <div className='demoSlice' />
              </div>
              <div className='demoContainer'>
                <div className='demoContainerInset'>
                  <video src='https://frame.nyc3.digitaloceanspaces.com/assets/mp4/Frame-Demo-RC1-Small.mp4' type='video/mp4' autoPlay loop playsInline muted />
                </div>
              </div>
            </div>
            <div className='quote'>{'"Frame is the most rock-solid way to interact with the decentralized web. It is also the easiest and smoothest, and it is a pioneer in usability. I have moved millions of dollars with Frame, and never went though a single hiccup."'}</div>
            <div className='name highlight'>{'Luis Cuende, CEO, Aragon One'}</div>
            <div id='signup' className='email'>
              {this.renderEmailInternal()}
              <span>{'Sign up to recieve updates about Frame'}</span>
            </div>
            <div className='supporters'>
              <a href={'https://aragon.org'}><img src={'https://frame.nyc3.digitaloceanspaces.com/assets/pngs/aragon.png'} height='80px' /></a>
              <div className='supportersText'> {'Frame is supported by Aragon Nest'} </div>
            </div>
            <div className='social'>
              <a href={'https://twitter.com/frame_eth'} style={{ marginTop: '-4px', marginRight: '30px', marginLeft: '-10px' }}>{svg.twitter(49)}</a>
              <a href={'https://github.com/floating/frame'}>{svg.octicon('mark-github', { height: '30px' })}</a>
            </div>
            <div className='needHelp'>
              {'Need help?'}
              <a target='_blank' href='https://github.com/floating/frame/issues/new'> {'Open an issue'} </a>
              {'or'}
              <a target='_blank' href='https://gitter.im/framehq/general'> {'come chat'}</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App
