import React from 'react'
// const ReactDOM = require('react-dom')
import Restore from 'react-restore'
import { CSSTransitionGroup } from 'react-transition-group'
import Web3 from 'web3'
import svg from '../../../../resource/svg'

const networks = { 1: 'Mainnet', 3: 'Ropsten', 4: 'Rinkeby', 42: 'Kovan' }

class Drop extends React.Component {
  constructor (...args) {
    super(...args)
    this.state = {
      accounts: [],
      showTransactions: false,
      currentTransaction: '',
      providerError: ''
    }
  }
  openTx (hash) {
    window.open('http://rinkeby.etherscan.io/tx/' + hash)
  }
  testTransaction () {
    let tx = {
      value: Web3.utils.toHex(Math.round(10000000000000000 * Math.random())),
      to: this.store('accounts', 0),
      from: this.store('accounts', 0)
    }
    this.store.setPending()
    try {
      window.web3.eth.sendTransaction(tx).on('transactionHash', hash => {
        // console.log('TX BACK', hash)
        this.store.insertTransaction({ hash })
        this.store.notice(`tx_success`)
      }).on('error', err => {
        // console.log(err)
        // console.log('ERROR BACK', err)
        this.store.notice(err.message)
      }).on('confirmation', (confirmationNumber, receipt) => {
        this.store.updateConfirmations(receipt.transactionHash, confirmationNumber)
      })
    } catch (err) {
      this.store.notice(err.message)
    }
  }
  getBalance () {
    window.web3.eth.getBalance(this.store('accounts', 0)).then(res => {
      this.store.notice(`Balance: ${Web3.utils.fromWei(res)}`)
    }).catch(err => {
      this.store.notice(err.message)
    })
  }
  getGasPrice () {
    window.web3.eth.getGasPrice().then(res => {
      this.store.notice(`Gas price: ${Web3.utils.fromWei(res)}`)
    }).catch(err => {
      this.store.notice(err.message)
    })
  }
  setCurrentTransaction (tHash) {
    return e => this.setState({ currentTransaction: this.store('transactions', tHash, 'data') })
  }
  test () {
    return (
      <div className='titleSlide'>
        {this.store('notice') ? (
          <div className='accountNotice'>{(_ => {
            if (this.store('notice') === 'tx_success') {
              return (
                <div key={'success'} className='noticeStatusInner'>
                  <div className='check'>
                    <div className='checkIcon'><div style={{ marginTop: '10px', marginLeft: '1px' }}>{svg.octicon('check', { height: '60px' })}</div></div>
                  </div>
                  <div className='noticeStatusText'>{'Transaction Successful'}</div>
                </div>
              )
            } else if (this.store('notice') === 'Returned error: user declined transaction') {
              return (
                <div key={'decline'} className='noticeStatusInner'>
                  <div className='check'>
                    <div className='checkIcon'><div style={{ marginTop: '8px' }}>{svg.octicon('circle-slash', { height: '80px' })}</div></div>
                  </div>
                  <div className='noticeStatusText'>{'Transaction Declined'}</div>
                </div>
              )
            } else if (this.store('notice') === 'pending') {
              return (
                <div key={'pending'} className='noticeStatusInner'>
                  <div className='check'>
                    <div className='checkIcon'><div className='loader' /></div>
                  </div>
                  <div className='noticeStatusText'>{'Transaction Pending'}</div>
                </div>
              )
            } else {
              return (
                <div key={'pending'} className='noticeStatusInner'>
                  <div className='check'>
                    <div className='checkIcon'>{svg.logo(40)}</div>
                  </div>
                  <div className='noticeStatusText'>{this.store('notice')}</div>
                </div>
              )
            }
          })()}</div>
        ) : (
          <div className='accountNotice'>
            <div key={'pending'} className='noticeStatusInner'>
              <div className='check'><div className='checkIcon'>{svg.logo(40)}</div></div>
              <div className='noticeStatusText'>{'Welcome!'}</div>
            </div>
          </div>
        )}
        <div className={'networkId'}>
          {'You\'ve sucessfully connected via'}
          <span className='highlight'> {'Frame'} </span>
          <br />
          {'and are currently using'}
          <span className='highlight'> {networks[this.store('netId')]} </span>
          {'Ethereum'}
        </div>
        <div className='accountMain'>
          <div className='accountBalance'><span style={{ fontSize: '19px', fontWeight: '400', marginRight: '9px', marginTop: '3px' }}>{'Ξ'}</span> <span className='num'>{parseFloat(this.store('balance')).toFixed(4)}</span></div>
          <div className='accountSubtitle'>{'your balance'}</div>
          <div className='accountAddress num'>{this.store('accounts', 0)}</div>
          <div className='accountSubtitle'>{'your address'}</div>
        </div>
        {this.store('netId') === '4' ? (
          <div className='buttonWrap'>
            <div className='button' onClick={() => this.testTransaction()}>{'Send A Rinkeby Test Transaction'}</div>
          </div>
        ) : (
          <div className='buttonWrap buttonWrapDisabled'>
            <div className='button'>
              {'Switch to Rinkeby to send test transaction'}
            </div>
          </div>
        )}
      </div>
    )
  }

  connect () {
    let supported = this.store('browserSupported')
    let ws = this.store('ws')
    let accounts = this.store('accounts')
    let connected = ws && accounts.length
    // console.log('connection render', ws, accounts, connected)
    return (
      <div className='titleSlide'>
        <div className='connectionStatus'>
          <CSSTransitionGroup transitionName={'slideUp'} transitionEnterTimeout={320} transitionLeaveTimeout={320}>
            {connected ? (
              <div key={'socket' + ws} className='connectionStatusInner'>
                <div className='check'>
                  <div className='checkIcon'>
                    <div style={{ marginTop: '10px', marginLeft: '1px' }}>{svg.octicon('check', { height: '60px' })}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div key={'socket' + ws} className='connectionStatusInner'>
                <div className='loader' />
              </div>
            )}
          </CSSTransitionGroup>
        </div>
        <div className='connectionStatusText'>{ws ? !connected ? 'Connected to Frame!' : 'Good to go!' : supported ? 'Trying to connect to Frame...' : 'Connecting to Frame with your browser is not currently supported...'}</div>
        <div className={ws && connected ? 'extrasWrap extrasWrapHidden' : 'extrasWrap'}>
          <div style={{ fontSize: '14px', paddingTop: '10px' }}>{supported ? ws && !connected ? 'Waiting for account...' : 'Open Frame to continue' : 'Please use Chrome or Firefox'}</div>
          <div className='launchGuide' style={{ fontSize: '14px', paddingTop: '10px', display: ws ? 'none' : 'flex' }}>{svg.logo(20)}</div>
          <div style={{ fontSize: '14px', paddingTop: '10px', display: ws ? 'none' : 'flex' }}>{'Frame will launch in your task/menu bar'}</div>
        </div>
      </div>
    )
  }
  intro () {
    return (
      <div className='titleSlide'>
        {svg.logo(140)}
      </div>
    )
  }
  render () {
    let slideMode = this.store('slide.mode')
    let direction = this.store('slide.direction')
    let ws = this.store('ws')
    let accounts = this.store('accounts')
    let connected = ws && accounts.length
    if (!connected && slideMode === 'test') slideMode = 'connect'
    let slide = this[slideMode].bind(this)
    return (
      <div id='dapp'>
        <CSSTransitionGroup style={{ width: '100%' }} transitionName={direction === '>' ? 'slideUp' : 'slideDown'} transitionEnterTimeout={320} transitionLeaveTimeout={320}>
          <div id='slide' key={'slide' + slideMode}>
            <div className='slideWrap'>
              {slide ? slide() : `Slide ${slideMode} not found.`}
            </div>
          </div>
        </CSSTransitionGroup>
      </div>
    )
  }
}

export default Restore.connect(Drop)
